import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/service/message.service';
import { UserService } from 'src/app/service/store/user.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
})
export class SubscriptionPageComponent implements OnInit {
  loading = false;
  seats: any[] = [];
  self = false;
  query = '';
  textMessage: any;
  isSmall = false;

  constructor(
    private userService: UserService,
    private api: UserApiService,
    private message: MessageService,
    private title: Title,
    private meta: Meta,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Subscription - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: "User's subscription list. check your account.",
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  normalBanner = true;
  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.getSelf();

    //特定banner
    // this.normalBanner = false;
    // const date = new Date().getTime();
    // const closeDate = new Date('2023/11/26 00:00:00').getTime();
    // if (date < closeDate) {
    //   this.normalBanner = false;
    // }
  }

  loadingLogin = false;
  async getSelf() {
    try {
      this.loadingLogin = true;
      const r = await this.api.getSelf();
      this.userService.set(r.data);
      if (r.data) {
        this.self = true;
        this.findUserBusSeats();
      }
    } catch (error) {
    } finally {
      this.loadingLogin = false;
    }
  }

  async findUserBusSeats() {
    try {
      this.loading = true;
      const r = await this.api.findUserBusSeats(this.query);
      this.seats = r.data;
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
    }
  }

  gotoAffiliate() {
    if (this.self) {
      this.router.navigate(['/affiliate']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
