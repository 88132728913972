import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-earned-card',
  templateUrl: './total-earned-card.component.html',
  styleUrls: ['./total-earned-card.component.scss'],
})
export class TotalEarnedCardComponent {
  @Input() data: any;
}
