import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss'],
})
export class AboutUsPageComponent implements OnInit {
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('About US - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Information about GoingBus',
    });
  }

  ngOnInit(): void {}
}
