import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-update-user-email-page',
  templateUrl: './update-user-email-page.component.html',
  styleUrls: ['./update-user-email-page.component.scss'],
})
export class UpdateUserEmailPageComponent implements OnInit {
  error = { code: false, email: false };
  email = '';
  formGroup: UntypedFormGroup;
  textMessage: any;
  isSmall: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: UserApiService,
    private message: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translateService: TranslateService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Update Email - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Update user email.',
    });

    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error.email = false;
      this.error.code = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  submit() {
    const error1 = this.formGroup.controls['email'].errors;
    const error2 = this.formGroup.controls['code'].errors;
    if (!error1 && !error2) {
      this.updateEmail();
    } else if (error1) {
      this.error.email = true;
      this.error.code = false;
    } else if (error2) {
      this.error.code = true;
      this.error.email = false;
    }
  }

  loading = false;
  async updateEmail() {
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('sendEmailCode')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }
    const code = this.formGroup.value.code;
    const email = this.formGroup.value.email;
    try {
      this.loading = true;
      const r = await this.api.updateEmail(code, email, token);
      if (r.status === 1101) {
        this.message.warn(this.textMessage.CodeInvalid);
      } else if (r.status === 1104) {
        this.message.warn(this.textMessage.EmailUsed);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.message.info(this.textMessage.EmailUpdated);
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
    }
  }
}
