import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AfterWithdrawModalComponent } from 'src/app/components/withdraw/after-withdraw-modal/after-withdraw-modal.component';
import { MessageService } from 'src/app/service/message.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-withdraw-page',
  templateUrl: './withdraw-page.component.html',
  styleUrls: ['./withdraw-page.component.scss'],
})
export class WithdrawPageComponent implements OnInit {
  formGroup: UntypedFormGroup;
  commission: any;
  error = { account: false, amount: false, bank: false, name: false };
  textMessage: any;
  records: any[] = [];
  page = 1;
  pageSize = 5;
  total = 0;
  isSmall: any;

  constructor(
    private api: UserApiService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private translateService: TranslateService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmall = result.matches; // 如果匹配手机模式，则为 true
      });
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Withdraw - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Withdraw. Check withdrawal list',
    });

    this.formGroup = this.fb.group({
      account: ['', [Validators.required, Validators.email]],
      currency: ['usd', [Validators.required]],
      method: ['Paypal', [Validators.required]],
      amount: [
        '',
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'),
        ],
      ],
      bank: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(13),
          Validators.maxLength(13),
        ],
      ],
      name: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error.account = false;
      this.error.amount = false;
      this.error.bank = false;
      this.error.name = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.findAffiliate();
    this.findUserWithdrawals();
  }

  async findAffiliate() {
    try {
      this.loadingAffiliate = true;
      const r = await this.api.findAffiliate();
      const data = r.data;
      this.commission = data.available_commission;
    } catch (error) {
    } finally {
      this.loadingAffiliate = false;
    }
  }

  loadingAffiliate = false;
  errorText = '';

  checkError() {
    var errors;
    if (this.formGroup.value['method'] === 'Kakao Bank') {
      errors = this.formGroup.controls['name'].errors;
      if (errors) {
        this.error.amount = false;
        this.error.account = false;
        this.error.bank = false;
        this.error.name = true;
      }

      errors = this.formGroup.controls['bank'].errors;
      if (errors) {
        this.error.amount = false;
        this.error.account = false;
        this.error.bank = true;
        this.error.name = false;
      }
    } else {
      errors = this.formGroup.controls['account'].errors;
      if (errors) {
        this.error.amount = false;
        this.error.account = true;
        this.error.bank = false;
        this.error.name = false;
      }
    }
    errors = this.formGroup.controls['amount'].errors;
    if (errors) {
      this.error.amount = true;
      this.error.account = false;
      this.error.bank = false;
      this.error.name = false;
    }
  }
  async withdraw() {
    this.checkError();
    

    if (this.formGroup.value['method'] !== 'Kakao Bank') {
      if (this.error.account || this.error.amount) {
        return;
      }
    } else {
      if (this.error.bank || this.error.amount || this.error.name) {
        return;
      }
    }

    var account;
    if (this.formGroup.value['method'] !== 'Kakao Bank') {
      account = this.formGroup.value.account;
    } else {
      account = this.formGroup.value.bank;
    }

    const currency = this.formGroup.value.currency;
    const method = this.formGroup.value.method;
    const amount = Number(this.formGroup.value.amount);
    const name = this.formGroup.value.name;
    try {
      this.loadingAffiliate = true;
      const r = await this.api.postUserWithdrawal(
        account,
        name,
        amount,
        currency,
        method,
        true,
        ''
      );
      if (r.status === 1107) {
        this.message.warn(this.textMessage.InsufficientBalance);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.dialog.open(AfterWithdrawModalComponent,{
          panelClass: 'order-dialog-container'
        });
        this.findUserWithdrawals();
        this.findAffiliate();
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loadingAffiliate = false;
    }
  }

  loading = true;
  async findUserWithdrawals() {
    try {
      this.loading = true;
      const r = await this.api.findUserWithdrawals(this.page, this.pageSize);
      const data = r.data;
      this.records = data.list;
      this.page = data.page;
      this.pageSize = data.page_size;
      this.total = data.total;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  onPageChange(e: any) {
    this.page = e;
    this.findUserWithdrawals();
  }
}
