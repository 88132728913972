import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, DoCheck } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';
import { UserApiService } from 'src/app/service/user-api.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, DoCheck {
  receivedData: any;
  isSmall: any;

  constructor(
    private api: UserApiService,
    private router: Router,
    private loadingDataService: LoadingDataService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Login - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Login GoingBus for better experience.',
    });
  }

  ngOnInit(): void {
    this.getSelf();
  }

  ngDoCheck(): void {
    this.loading = this.loadingDataService.loading();
  }

  loading = false;

  async getSelf() {
    try {
      this.loading = true;
      const r = await this.api.getSelf();
      if (r.data) {
        this.router.navigate(['']);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
