<app-spinner class="new-container" [loading]="loading">
  <div class="content" *ngIf="self">
    <mat-card>
      <h1>User Profile</h1>
      <mat-card-header>
        <div (click)="updateAvatar()">
          <app-user-avatar
            [fileid]="self.avatar"
            width="200"
            height="200"
            *ngIf="self"></app-user-avatar>
          <mat-icon
            ><img
              alt="choose"
              width="24"
              height="24"
              src="../../../assets/images/user-profile/Icon-choose.svg"
          /></mat-icon>
        </div>
      </mat-card-header>
      <form [formGroup]="formGroup">
        <mat-card-content>
          <!-- <div class="info-row">
            <span class="label">{{ "inputLabel.Nickname" | translate }}</span>
            <span class="value col" (click)="updateNickname()">
              {{ self.nickname }}
              <img
                alt="arrow right"
                width="20"
                height="20"
                src="../../../assets/images/base/Icon-arrow-right.svg"
              />
            </span>
          </div> -->
          <!-- 昵称 -->
          <div class="new-info-row">
            <span class="label">{{ "inputLabel.Nickname" | translate }}</span>
            <!-- <span class="change col" (click)="updateNickname()">
              修改昵称
              <img
                alt="arrow right"
                width="20"
                height="20"
                src="../../../assets/images/base/Icon-arrow-right.svg"
              />
            </span> -->
          </div>
          <app-input class="nickname-input"
          [placeholder]='self.nickname'
          formControlName="nickname"></app-input>
          <div class="error">
            <app-error *ngIf="errorText">{{
              "textError.Nickname" | translate
            }}</app-error>
          </div>
          <!-- 邮箱 -->
          <div class="new-info-row">
            <span class="label">{{ "inputLabel.Email" | translate }}</span>
            <span class="change col" routerLink="/user/email">
              修改邮箱？
            </span>
          </div>
          <app-input class="email-input"
          [placeholder]='self.email'
          [disabled]="InputDisabled"
          ></app-input>
          <!-- 密码 -->
          <div class="new-info-row">
            <span class="label">{{ "placeholder.Password" | translate }}</span>
            <span class="change col" routerLink="/user/password">
              修改密码？
            </span>
          </div>
          <app-input class="password-input"
          placeholder='******************'
          [disabled]="InputDisabled"></app-input>
          <!-- <div class="space"></div>
          <div class="info-row">
            <span class="label">{{ "inputLabel.Email" | translate }}</span>
            <span class="value col" routerLink="/user/email">
              {{ self.email }}
              <img
                alt="arrow right"
                width="20"
                height="20"
                src="../../../assets/images/base/Icon-arrow-right.svg"
              />
            </span>
          </div>
          <div class="space"></div> -->
        </mat-card-content>
      </form>
      <mat-card-footer>
        <app-button
          color="#602369"
          fontColor="#ffffff"
          width="100%"
          height="60px"
          (click)="apply()"
          >保存设置</app-button
        >
      </mat-card-footer>
    </mat-card>
  </div>
</app-spinner>

<!-- 旧的组件 -->
<!-- <div class="container">
  <div class="content" *ngIf="self">
    <mat-card>
      <mat-card-header>
        <div (click)="updateAvatar()">
          <app-user-avatar
            [fileid]="self.avatar"
            width="90"
            height="90"
            *ngIf="self"
          ></app-user-avatar>
          <mat-icon
            ><img
              alt="choose"
              width="24"
              height="24"
              src="../../../assets/images/user-profile/Icon-choose.svg"
          /></mat-icon>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="info-row">
          <span class="label">{{ "inputLabel.Nickname" | translate }}</span>
          <span class="value col" (click)="updateNickname()">
            {{ self.nickname }}
            <img
              alt="arrow right"
              width="20"
              height="20"
              src="../../../assets/images/base/Icon-arrow-right.svg"
            />
          </span>
        </div>
        <div class="space"></div>
        <div class="info-row">
          <span class="label">{{ "inputLabel.Email" | translate }}</span>
          <span class="value col" routerLink="/user/email">
            {{ self.email }}
            <img
              alt="arrow right"
              width="20"
              height="20"
              src="../../../assets/images/base/Icon-arrow-right.svg"
            />
          </span>
        </div>
        <div class="space"></div>
      </mat-card-content>
      <mat-card-footer>
        <app-button
          color="#602369"
          fontColor="#ffffff"
          width="100%"
          height="48px"
          routerLink="/user/password"
          >{{ "button.ChangePassword" | translate }}</app-button
        >
      </mat-card-footer>
    </mat-card>
  </div>
</div> -->
