import { Component } from "@angular/core";


@Component({
    selector: 'app-partner-panel',
    templateUrl: './partner-panel.component.html',
    styleUrls: ['./partner-panel.component.scss'],
})
export class PartnerPanelComponent{
    cards = [
        {
            alt: 'Influencer',
            image: '../../../../assets/images/about-us/Influencer.png',
            title: 'Influencer',
            content: 'Sharing referral link about PremLogin subscriptions sold on social media platforms.',
        },
        {
            alt: 'Group Administrator',
            image: '../../../../assets/images/about-us/Group-Administrator.png',
            title: 'Group Administrator',
            content: 'Share PremLogin referral links or promo codes in social groups.'
        },
        {
            alt: 'Blogger',
            image: '../../../../assets/images/about-us/Blogger.png',
            title: 'Blogger',
            content: 'Promote PremLogin subscriptions in your articles.'
        },
        {
            alt: 'Review Websites',
            image: '../../../../assets/images/about-us/Review-Websites.png',
            title: 'Review Websites',
            content: 'Deeply experience PremLogin and review our products as a site owner.'
        },
        {
            alt: 'Forum Owner/Moderator',
            image: '../../../../assets/images/about-us/Forum.png',
            title: 'Forum Owner/Moderator',
            content: 'Share your experience and referral link in local forums and communities.'
        },
        {
            alt: 'Coupon Websites',
            image: '../../../../assets/images/about-us/Coupon-Websites.png',
            title: 'Coupon Websites',
            content: 'Share our promo code on your coupon websites.'
        }
    ]
}