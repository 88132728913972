<app-spinner [loading]="loading">
  <div class="container">
    <div
      class="top"
      [ngStyle]="{
        direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
      }"
    >
      <span class="name"
        >#{{ logs[0]?.workorder.order.bus?.id }}
        {{ logs[0]?.workorder.problem_platform_re[0].problem }}</span
      >
      <!-- <span class="status">
        <ng-container *ngIf="logs[0]?.workorder.status === 'ReplyReceived'">
          {{ "pageHelp.TicketStatus1" | translate }}
        </ng-container>
        <ng-container
          *ngIf="logs[0]?.workorder.status === 'NoResponseReceived'"
          >{{ "pageHelp.TicketStatus2" | translate }}</ng-container
        >
        <ng-container
          *ngIf="
            logs[0]?.workorder.status === 'ProactiveCompletion' ||
            logs[0]?.workorder.status === 'PassiveCompletion'
          "
          >{{ "pageHelp.TicketStatus3" | translate }}</ng-container
        >
      </span> -->
    </div>
    <div class="content" [formGroup]="formGroup">
      <div
        class="title"
        [ngStyle]="{
          direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
        }"
      >
        <span>{{
          logs[0]?.workorder.order.bus_type_snapshot.platform.name
        }}</span
        ><span>&nbsp;-&nbsp;</span
        ><span>{{ logs[0]?.workorder.order?.bus?.bus_account.username }}</span>
      </div>
      <ng-container *ngFor="let item of logs">
        <div class="divider"></div>
        <div
          class="item"
          [ngStyle]="{
            direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
          }"
        >
          <div class="avatar">
            <ng-container *ngIf="item.sender === 'User'"
              ><app-image [fileid]="item.workorder.user.avatar"></app-image
            ></ng-container>
            <ng-container *ngIf="item.sender === 'CustomerService'"
              ><img src="../../../assets/images/base/logo-round.webp" alt=""
            /></ng-container>
          </div>
          <div class="detail">
            <div class="name">
              <ng-container *ngIf="item.sender === 'User'">{{
                item.workorder.user.email
              }}</ng-container>
              <ng-container *ngIf="item.sender === 'CustomerService'">
                GOINGBUS
              </ng-container>
            </div>
            <div class="qa" [innerHTML]="item.message"></div>
            <div class="time">
              <app-datetime [text]="item.created_time"></app-datetime>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="divider"></div>
      <div class="editor">
        <app-quill-editor
        formControlName="detail"
        [content]="localStorageLanguage === 'ar' ? 'ar' : ''"
      ></app-quill-editor>
      </div>
      
      <div
        class="button"
        [ngStyle]="{
          direction: localStorageLanguage === 'ar' ? 'rtl' : 'ltr'
        }"
      >
        <app-button
          type="2"
          class="button1"
          mat-stroked-button
          (click)="close()"
          [disabled]="buttonLoading"
          height="50px"
          width="200px"
        >
          {{ "button.CloseTicket" | translate }}
        </app-button>
        <app-button
          type="1"
          class="button2"
          mat-stroked-button
          (click)="reply()"
          [disabled]="formGroup.invalid || buttonLoading"
          [ngStyle]="{ color: formGroup.invalid ? '#b387ba !important' : '#602369 !important' }"
          height="50px"
          width="200px"
        >
          {{ "button.AddReply" | translate }}
        </app-button>
      </div>
    </div>
  </div>
</app-spinner>
