<div class="container">
  <div class="content">
    <div class="v404">
      <img
        alt="404"
        width="481"
        height="430"
        src="../../../assets/images/not-found/not-found-page.webp"
      />
      <h1>{{ "pageNotFound.Content1" | translate }}</h1>
      <button mat-raised-button (click)="back()">
        {{ "pageNotFound.Content2" | translate }}
      </button>
    </div>
  </div>
</div>
