import { Component, OnInit } from '@angular/core';
import { UserApiService } from 'src/app/service/user-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-affiliate-page',
  templateUrl: './affiliate-page.component.html',
  styleUrls: ['./affiliate-page.component.scss'],
})
export class AffiliatePageComponent implements OnInit {
  data: any;
  isSmall: any;
  translatePageAffiliate: any;

  constructor(
    private api: UserApiService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Affiliate Dashboard - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Withdraw. Check order list and order statistics charts.',
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('pageAffiliate').subscribe((text: string) => {
        this.translatePageAffiliate = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('pageAffiliate').subscribe((text: string) => {
      this.translatePageAffiliate = text;
    });
    this.findAffiliate();
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmall = result.matches;
      })

  }


  loading = false;
  async findAffiliate() {
    try {
      this.loading = true;
      const r = await this.api.findAffiliate();
      this.data = r.data;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
