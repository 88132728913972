<mat-card>
  <mat-card-header>
    <span>
      {{ "pageAffiliate.TotalEarned" | translate }}
      <!-- <img
        alt="union"
        width="28"
        height="28"
        src="../../../../assets/images/affiliate/Icon-earn.svg"
      /> -->
    </span>
  </mat-card-header>
  <mat-card-content class="mat-card-content">
    <div class="money">
      <span>
        <div class="text1">$ {{ data?.total_commission.usd | number }}</div>
        <div class="text2">$ {{ data?.available_commission.usd | number }}</div>
      </span>
      <span>
        <div class="text1">€ {{ data?.total_commission.eur | number }}</div>
        <div class="text2">€ {{ data?.available_commission.eur | number }}</div>
      </span>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <app-button
      color="#602369"
      fontColor="#ffffff"
      fontSize="14px"
      width="192px"
      height="40px"
      routerLink="/withdraw"
    >
      {{ "button.Withdraw" | translate }}</app-button
    >
  </mat-card-actions>
</mat-card>
