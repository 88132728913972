<div class="container">
    <article class='mat-typography'>
        <h1>Privacy Policy</h1>
        <div class='card'>
            <div class="content">
                <h2>
                    Introduction
                </h2>
                <p>
                    PremLogin.com is dedicated to providing the safest and most useful streaming services while respecting and protecting your personal data. This Privacy Policy outlines our practices concerning the collection, use, access, transmission, storage, and processing of your personal information. By using our services, you consent to the practices described in this policy.
                </p>
            </div>  
            <div class="divide-line"></div>
            <div class="content">
                <h2>Updates to the Privacy Policy</h2>
                <p>We review our Privacy Policy regularly and may update it from time to time. If significant changes are made, we will notify you using the contact information you have provided. If required by law, we will also provide you with the opportunity to opt-out of these changes. Your continued use of PremLogin.com or our services after such updates signifies your acceptance of the revised policy.</p>

            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>Data Collection and Use</h2>
                <p>You can browse PremLogin.com without providing any personal data. However, to use certain services, personal data may be required. If processing your personal data is necessary and there is no legal basis for such processing, we will seek your consent.</p>
            </div>
            <div class="divide-line">
                <h2>Data Security</h2>
                <p>We take practical steps to secure your personal data and prevent unauthorized or accidental access, deletion, or other misuse. This includes implementing appropriate physical, technical, and procedural security measures. However, please note that data transmission over the Internet may have security vulnerabilities, and absolute protection cannot be guaranteed.</p>

            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>Compliance with Laws</h2>
                <p>We comply with the General Data Protection Regulation (GDPR), the Personal Data Protection Act (PDPA) of 2010, and applicable Chinese laws regarding data protection.</p>
            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>Definitions</h2>
                <ul>
                    <li>
                        <h2>Personal Data</h2>
                        <p>Any information relating to an identified or identifiable natural person (data subject).</p>
                    </li>
                    <li>
                        <h2>Data Subject</h2>
                        <p>An identified or identifiable natural person whose personal data is processed by us.</p>
                    </li>
                    <li>
                        <h2>Processing</h2>
                        <p>Any operation or set of operations performed on personal data, whether automated or not.</p>
                    </li>
                    <li>
                        <h2>Processing Restrictions</h2>
                        <p>Marking stored personal data to restrict future processing.</p>
                    </li>
                    <li>
                        <h2>Pseudonymization</h2>
                        <p>Processing personal data in a way that it can no longer be attributed to a specific data subject without additional information.</p>
                    </li>
                </ul>
            </div>

            <div class="divide-line"></div>
            <div class="content">
                <h2>Cookies</h2>
                <p>We use cookies for various purposes, including:</p>
                <ul>
                    <li>
                        <p>
                            Statistical Analysis: To understand website usage and improve user experience.
                        </p>
                    </li>
                    <li>
                        <p>
                            Remembering Settings: To remember your preferences between browsing sessions.
                        </p>
                    </li>
                </ul>
                <p>
                    Cookies can be session-based or persistent. You can manage or delete cookies through your browser settings.
                </p>
            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>
                    Data Analysis
                </h2>
                <p>
                    We may share aggregated data with third parties for analytical purposes. We also store personal data provided by registered users in their user profiles, which they can view, edit, or delete at any time.
                </p>
            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>Your Rights</h2>
                <ul>
                    <h2>Under GDPR</h2>
                    <ul>
                        <li>
                            <p>
                                Right to Access: Know what personal data we hold about you.
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Rectification: Correct inaccurate personal data.
                                
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Erasure: Delete personal data under certain conditions.
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Restriction of Processing: Restrict processing under certain conditions.
                                
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Data Portability: Receive personal data in a structured format and transfer it to another data controller.
                            </p>
                        </li>
                        <li>
                            <p>
                                Right to Object: Object to processing based on legitimate interests or direct marketing.
                            </p>
                        </li>
                    </ul>
                </ul>
            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>Third-Party Login and Authorization</h2>
                <p>
                    If you log in using third-party authentication services (e.g., Google, Facebook), we may receive data such as your name and email address from these services. By using these services, you agree with them sharing this information with us.
                </p>
            </div>
            <div class="divide-line"></div>
            <div class="content">
                <h2>General Provisions</h2>
                <p>In case of any inconsistency between the English version and translated versions of this Privacy Policy, the English version shall prevail.</p>
                <ul>
                    <li>
                        <p>
                            This service is jointly provided by the following companies:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    1. Hong Kong Company:
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Company Name: Hongkong PremLogin Limited
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Registered Address: Room D07, 8th Floor, Phase 2, Kaide Factory Building, 99 King Fuk Street, San Po Kong, Hong Kong
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    2. UK Company:
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Company Name: PREMLOGIN LIMITED
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Registered Address: Suite 6630 61 Bridge Street, Kington, United Kingdom, HR5 3DJ
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>Relationship Description:</p>
                <ul>
                    <li>
                        <p>
                            Hongkong PremLogin Limited and PREMLOGIN LIMITED are affiliated companies, jointly operating and managing this e-commerce platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            All matters related to this platform are handled and managed by the above-mentioned companies.
                        </p>
                    </li>
                </ul>
                <p>
                    If you have any questions, you can contact us through the following ways:
                </p>
                <ul>
                    <li>
                        <p>
                            Email: support@premlogin.com
                        </p>
                    </li>
                    <li>
                        <p>
                            Governing Law: United Kingdom
                        </p>
                    </li>
                    <li>
                        <p>
                            Jurisdiction: Courts of the United Kingdom
                        </p>
                    </li>
                </ul>
            </div>


        </div>
    </article>
</div>
