import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserApiService } from 'src/app/service/user-api.service';
import {
  letterAndNumberValidator,
  passwordValidator,
} from 'src/app/utils/form';

@Component({
  selector: 'app-update-user-password-page',
  templateUrl: './update-user-password-page.component.html',
  styleUrls: ['./update-user-password-page.component.scss'],
})
export class UpdateUserPasswordPageComponent implements OnInit {
  formGroup!: UntypedFormGroup;
  error = { ConfirmPassword: false, PasswordsInconsistent: false };
  errorText = '';
  textError: any;
  isSmall: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: UserApiService,
    private router: Router,
    private translateService: TranslateService,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Update Password - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Update user password.',
    });

    this.formGroup = this.fb.group({
      password1: ['', [passwordValidator, letterAndNumberValidator]],
      password2: ['', [Validators.required]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error.ConfirmPassword = false;
      this.error.PasswordsInconsistent = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textError').subscribe((text: string) => {
        this.textError = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textError').subscribe((text: string) => {
      this.textError = text;
    });
  }

  submit() {
    const error1 = this.formGroup.controls['password1'].errors;
    const error2 = this.formGroup.controls['password2'].errors;
    if (!error1 && !error2) {
      this.changePassword();
    } else if (error2) {
      this.error.ConfirmPassword = true;
    }
  }

  loading = false;
  async changePassword() {
    const password1 = this.formGroup.value['password1'];
    const password2 = this.formGroup.value['password2'];
    if (password1 != password2) {
      this.error.PasswordsInconsistent = true;
      return;
    }
    try {
      this.loading = true;
      const r = await this.api.updateEmailPassword(password1);
      this.router.navigate(['/user/profile']);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
