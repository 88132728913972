<mat-toolbar [ngStyle]="{ 'background': backgroundColor }">
<!-- <mat-toolbar> -->
  <div class="container">
    <div style="width: 100%">
      <div class="icon" routerLink="/">
        <img
          alt="logo"
          width=auto
          height="34"
          src="../../../../assets/images/base/logo-min.svg" 
        />
      </div>



      <div class="menu">
        <ng-container *ngIf="!isSmall">
          <app-page-header-link-button url="/">{{
            "pageHeader.Home" | translate
          }}</app-page-header-link-button>

          <app-page-header-link-button url="/affiliate">{{
            "pageHeader.Affiliate" | translate
          }}</app-page-header-link-button>

          <app-page-header-link-button url="/help">
            {{ "pageHeader.HelpSupport" | translate }}
          </app-page-header-link-button>
          <app-page-header-link-button
            url="/subscription"
            [ngClass]="{ small: isSmall }"
          >
            {{ "pageHeader.Subscription" | translate }}
          </app-page-header-link-button>
        </ng-container>

        <ng-container *ngIf="isSmall">

          <div class="drawer-container">
            <button class="list-button" (click)="toggleDrawer()">
              <img
              src="../../../../assets/images/page-header/Icon-list-button.png"
              height="20px"
              >
            </button>
            <div class="drawer" [class.open]="isOpen">
              <div class="drawer-header">
                <div class="icon" routerLink="/">
                  <img
                    alt="logo"
                    width=auto
                    height="34"
                    src="../../../../assets/images/base/logo-min.svg" 
                  />
                </div>
                <div class="icon" (click)="closeDrawer()">
                  <img
                    alt="logo"
                    width=auto
                    height="17"
                    src="../../../../assets/images/page-header/Icon-close.png" 
                  />
                </div>
              </div>
              <div class="divide-line">

              </div>
              <app-page-header-link-button url="/">{{
                "pageHeader.Home" | translate
              }}</app-page-header-link-button>
    
              <app-page-header-link-button url="/affiliate">{{
                "pageHeader.Affiliate" | translate
              }}</app-page-header-link-button>
    
              <app-page-header-link-button url="/help">
                {{ "pageHeader.HelpSupport" | translate }}
              </app-page-header-link-button>
              <app-page-header-link-button
                url="/subscription"
                [ngClass]="{ small: isSmall }"
              >
                {{ "pageHeader.Subscription" | translate }}
              </app-page-header-link-button>
            </div>
          </div>
        </ng-container>
        

        

        
      </div>
      <span class="spacer"></span>
      <div class="gift" (click)="beAffiliate()">
        <img 
          src="../../../../assets/images/page-header/Icon-gift.png"
          height="40"
          width="40"
          >
      </div>
      <app-user-avatar-with-menu></app-user-avatar-with-menu>
      
    </div>
  </div>
</mat-toolbar>
