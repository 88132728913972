import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us-send-successful-page',
  templateUrl: './contact-us-send-successful-page.component.html',
  styleUrls: ['./contact-us-send-successful-page.component.scss'],
})
export class ContactUsSendSuccessfulPageComponent {
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle('Contact US - GoingBus');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Contact GoingBus team',
    });
  }
}
