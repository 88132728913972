<mat-card>
  <mat-card-header
    >{{ "pageAffiliate.ReferLink" | translate }}
  </mat-card-header>
  <mat-card-content class="mat-card-content">
    <div class="link">
      <span>{{ link }}</span>
      <button
        (click)="copy()"

      >
        <img
          alt="copy"
          width="20px"
          height="20px"
          src="../../../../assets/images/affiliate/Icon-copy.png"
        >
      </button
      >
      <button
        (click)="share()"
      >
        <!-- {{ "button.Share" | translate }} -->
        <img
          alt="copy"
          width="20px"
          height="20px"
          src="../../../../assets/images/affiliate/Icon-share.png"
        >
      </button
      >
    </div>

  </mat-card-content>
  <mat-card-actions>
    <div class="qrcode">
      <qrcode
        [qrdata]="link"
        [width]="80"
        [errorCorrectionLevel]="'M'"
        elementType="img"
        [margin]="1"
      ></qrcode>
    </div>
    <div class="info">
      {{"pageAffiliate.ScanQR" | translate}}
    </div>
  </mat-card-actions>
</mat-card>
