<div class="point">
    <img [alt]="alt" [src]="image" height="90" />
  
    <div class="description">
      <div class="title">{{ title }}</div>
      <div class="contact">
        <div class="contact-way">{{ contactWay }}</div>
        <div class="contact-url">{{ contactUrl }}</div>
      </div>
      
  
    </div>
</div>
<div class="space"></div>