import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck,OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { UserApiService } from '../../service/user-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss'],
})
export class IndexPageComponent implements DoCheck {
  platform = '';
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private api: UserApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('GoingBus');
    } else {
      this.title.setTitle(
        'Shared premium subscriptions with lower price on GoingBus'
      );
    }

    this.meta.updateTag({
      name: 'description',
      content:
        'Purchase cheap and stable premium subscriptions at GoingBus. Including Netflix, Youtube, Disney+, Spotify, HBO, Hulu and so on.',
    });
    this.route.queryParams.subscribe((params) => {
      this.platform = params['platform'];
    });
  }

  flag = true;

  ngDoCheck(): void {
    if (!this.platform) return;

    const el = document.getElementById(this.platform);
    if (el && this.flag) {
      el?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      this.flag = false;
    }
  }

  // 用于验证码验证
  ngOnInit() {
    // 获取路由参数 :s
    this.route.params.subscribe((params) => {
      if (params['s']) {
        this.checkAffiliateCode(params['s']);
      }
    });
  }

  async checkAffiliateCode(code: string) {
    try {
      await this.api.checkAffiliateCode(code);
    } catch (error) {}
  }
}
